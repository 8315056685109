// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Navbar.tsx");
  import.meta.hot.lastModified = "1723971484078.9917";
}
// REMIX HMR END

import { useState } from "react";
import Hamburger from "~/images/hamburger.svg";
import Brand from "~/images/logo.png";
import navlinks from "~/data/navlinks";
import { Link, useFetcher } from "@remix-run/react";
const Navbar = ({
  token
}) => {
  _s();
  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const fetcherAPI = useFetcher();
  const handleLogout = () => {
    fetcherAPI.load("/api/logout");
  };
  return <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src={Brand} alt="" />
          </Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img src={Hamburger} alt="" />
        </div>
        <div className={`nav-elements ${showNavbar && "active"}`}>
          <ul>
            {navlinks.map((link, index) => <li key={index}>
                <Link to={link.path} onClick={handleShowNavbar}>
                  {link.name}
                </Link>
              </li>)}
            {token?.length ? <>
                <li>
                  <Link to="/profile" onClick={handleShowNavbar}>
                    Profile
                  </Link>
                </li>
                <li>
                  <Link to="/login" onClick={() => {
                handleLogout();
                handleShowNavbar();
              }}>
                    Logout
                  </Link>
                </li>
              </> : <>
                {/* <li>
                  <Link to="/login" onClick={handleShowNavbar}>
                    Login
                  </Link>
                 </li> */}
                <li>
                  <Link to="/signup" onClick={() => {
                handleShowNavbar();
              }}>
                    Get Started
                  </Link>
                </li>
              </>}
          </ul>
        </div>
      </div>
    </nav>;
};
_s(Navbar, "QIJX+i9+uTnypynKzYfyQKQDem0=", false, function () {
  return [useFetcher];
});
_c = Navbar;
export default Navbar;
var _c;
$RefreshReg$(_c, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;