// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, json, useLoaderData } from "@remix-run/react";
import stylesheet from "./app.css";
import Nav from "./components/Navbar";
import Footer from "./components/footer";
import footerLinks from "~/data/footerLinks";
import { useLocation } from "@remix-run/react";
import { useEffect, useState } from "react";
import HomeHeader from "./components/homeHeader";
import pathNames from "~/data/pathNames";
import SignupContextProvider from "./context/signupContext";
import { getJwtFromSession } from "./utls/sessionStorage";
export const loader = async () => {
  const jwt = await getJwtFromSession();
  return json({
    token: jwt
  });
};
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export default function App() {
  _s();
  const location = useLocation();
  const [pathName, setPathName] = useState("");
  const [token, setToken] = useState("");
  const loaderData = useLoaderData();
  const routesToHideNav = ["signup", "login", "chooseplan", "setnewpassword", "resetpassword"];
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);
  useEffect(() => {
    if (loaderData?.token?.jwt) setToken(loaderData.token.jwt);
  }, [loaderData]);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <SignupContextProvider>
          {!routesToHideNav.includes(pathName.slice(1).toLowerCase()) && <Nav token={token} />}

          {!routesToHideNav.includes(pathName.slice(1).toLowerCase()) && pathNames.includes(pathName.slice(1).toLowerCase()) && <HomeHeader />}

          <div className="main">
            <Outlet />
          </div>

          {!routesToHideNav.includes(pathName.slice(1).toLowerCase()) && <Footer data={footerLinks} />}

          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </SignupContextProvider>
      </body>
    </html>;
}
_s(App, "d10oEXphpKWrYiJitOJfsVsiT2s=", false, function () {
  return [useLocation, useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;