// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/homeHeader.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/homeHeader.tsx");
  import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import poster from "~/images/poster 4.jpg";
import skf from "~/images/kidsTextImage.png";
export const HomeHeader = () => {
  return <div className="banner">
      <img src={poster} alt="kids text image" />
      <div className="bannerContent">
        <img src={skf} alt="kids text image" />
      </div>
    </div>;
};
_c = HomeHeader;
export default HomeHeader;
var _c;
$RefreshReg$(_c, "HomeHeader");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;