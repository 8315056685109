// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/footerLinks.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

const footerLinks = [
  {
    heading: "Salam Kids TV",
    links: [
      {
        to: "/about",
        linkText: "About",
      },
      {
        to: "/parents",
        linkText: "Parents",
      },
      {
        to: "/creators",
        linkText: "Creators",
      },
    ],
  },
  {
    heading: "Business",
    links: [
      {
        to: "/brands",
        linkText: "Brands and Advertisers",
      },
      // {
      //   to: "/shop",
      //   linkText: "Shop",
      // },
    ],
  },
  {
    heading: "For Viewers",
    links: [
      {
        to: "/privacypolicy",
        linkText: "Privacy Policy",
      },
      {
        to: "/termsofservice",
        linkText: "Terms of Service",
      },
    ],
  },
];

export default footerLinks;
