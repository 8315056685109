// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/navlinks.js"
);
import.meta.hot.lastModified = "1716050307746.1453";
}
// REMIX HMR END

const navlinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Pricing",
    path: "/pricing",
  },
  {
    name: "About",
    path: "/about",
  },
  // {
  //   name: "Meet the founder",
  //   path: "/founder",
  // },
  {
    name: "For Creators",
    path: "/creators",
  },
  {
    name: "For Parents",
    path: "/parents",
  },
  {
    name: "For Partners",
    path: "/brands",
  },
  {
    name: "Contact Us",
    path: "/contactus",
  },
];
export default navlinks;
