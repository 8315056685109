// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/pathNames.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

const pathNames = [
  "",
  "parents",
  "creators",
  "privacypolicy",
  "termsofservice",
  "brands",
  "signup",
  "pricing",
  "about",
  "contactus",
];

export default pathNames;
